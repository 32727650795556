/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { actions } from "../../reducers/BlogsReducer";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "components/common/Spinner";
import { isArray } from "lodash";
import NoTabledata from "components/common/NoTabledata";
import { Helmet } from 'react-helmet';
import "../../pages/Packages/Packages.scss";
import "../../pages/Packages/PackageDetails.scss";
import { getBlogDetails } from "services/Blogs";
import { errorToast, getErrorObject } from "utilities/utils";

export default function BlogsDetails() {
  const { title } = useParams();
  const [blogDetail, setblogDetail] = useState(null);

  // const dispatch = useDispatch();
  const { data, isLoading } = useSelector((state) => state.blogs);

  // useEffect(() => {
  //   dispatch(
  //     actions.onRequest({
  //       forType: forType,
  //     })
  //   );
  // }, []);

  useEffect(() => {
    // dispatch(actions.onRequest({}));
    _getBlogDetails();
  }, []);

  const _getBlogDetails = async (payload) => {
    try {
      let response = await getBlogDetails(title);
      console.log("package detail response", response);
      setblogDetail(response?.data?.data);
    } catch (e) {
      const { message } = getErrorObject(e);
      errorToast({
        content: message,
      });
    }
  };

  // useEffect(() => {
  //   if (data) {
  //     if (isArray(data)) {
  //       console.log("data", data);
  //       let findPackage = data.find((pckg) => pckg._id === id);
  //       if (findPackage) {
  //         setblogDetail(findPackage);
  //       }
  //     }
  //   }
  // }, [data]);
  // const myStyle = {
  //   backgroundImage: `url(${blogDetail && blogDetail.image})`,
  // };
  return (
    <div className="page-packages">
      {blogDetail && (
        <div className="page-package-detail">
          <Helmet>
            <title>{blogDetail?.meta_title}</title>
            <meta name="description" content={blogDetail?.meta_desc} />
            {/* Add other meta tags as needed */}
          </Helmet>
          <div className="breadcrumb-section">
            <div className="container">
              <h1 className="breadcrumb-title text-capaitalize">
                {blogDetail.title}
              </h1>
            </div>
          </div>
          <div className="package-services">
            {/* List of packages */}
            <div className="pckg-section section-spacing">
              <div className="container">
                {isLoading && (
                  <div className="text-center">{<Spinner size={56} />}</div>
                )}
                <div className="row">
                  <div className="col-md-12">
                    <div className="pckg-list">
                      {!blogDetail && (
                        <NoTabledata subHeading="No data found" />
                      )}
                      {!isLoading && blogDetail && (
                        <div className="detail-container">
                          <div className="packg-details-content">
                            <div className="pckg-content-d">
                              <h5 className="sub-heading text-left">
                                {blogDetail.title}
                              </h5>
                            </div>

                            <div
                              dangerouslySetInnerHTML={{
                                __html: blogDetail.description,
                              }}
                            />
                            <hr />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
