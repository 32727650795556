
import "./tenyearvisa.scss"
import tenYearVisaImg from "../../assets/images/tenyearvisa.webp"
import imgTick from "../../assets/images/imagetick.webp"
import fbImg from "../../assets/images/socialicons/facebook.webp"
import waImg from "../../assets/images/socialicons/whatsapp.webp"
import instaImg from "../../assets/images/socialicons/instagram.webp"
import youtube from "../../assets/images/socialicons/youtube.webp"
import x from "../../assets/images/socialicons/x.webp"
import pinterest from "../../assets/images/socialicons/pinterest.webp"
import linkedin from "../../assets/images/socialicons/linkedin.webp"
import {useState} from "react";
import axios from "axios";
import Swal from "sweetalert2";
const TenYearVisa = () => {
    const [formData,setFormData] = useState({
        name:"",
        email:"",
        package:"",
        phoneNumber:"",
        message:"",

    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataNew = {
            type:"contactUs",
            fullName:formData?.name,
            email:formData?.email,
            mobileNumber:formData?.phoneNumber,
            package:"",
            message:formData?.message,

            // totalTravellers:"2"
        };
        try {
            const response = await axios.post("https://api.chicagohajj.com/website/inquiry", formDataNew);
            console.log(response.data);
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Your ContactUs form submit successfully",
                showConfirmButton: false,
                timer: 1500
            });
            setFormData({
                name: "",
                email: "",
                package: "",
                phoneNumber: "",
                message: "",
            });
            // handle successful response
        } catch (error) {
            console.error(error);
            // handle error
        }


    };
    return(
        <div className="tenyearvisa">
            <div  className="tenyearvisa-breadcrum"  style={{ backgroundImage: `url(${tenYearVisaImg})`, backgroundSize: 'cover', backgroundPosition: '50%,50%' }}>
                <h2>10 Year Saudi Visa</h2>
            </div>

            <div className="tenyearvisa-cardsdiv">
                <div className="tenyearvisa-cardsdiv-cardContent" >
                    <div className="tenyearvisa-cardsdiv-cardContent-carditems">
                        <div className="tenyearvisa-cardsdiv-cardContent-carditems-imgdiv">
                            <img src={imgTick} alt="imgTick"/>
                        </div>

                        <div className="tenyearvisa-cardsdiv-cardContent-carditems-headings">
                            <h3>Multiple Entries</h3>
                            <p>You are allowed to enter multiple times within 10 years, making it a great option for
                                those
                                looking to stay for long periods of time.</p>
                        </div>

                    </div>
                    <div className="tenyearvisa-cardsdiv-cardContent-carditems">
                        <div className="tenyearvisa-cardsdiv-cardContent-carditems-imgdiv">
                            <img src={imgTick} alt="imgTick"/>
                        </div>

                        <div className="tenyearvisa-cardsdiv-cardContent-carditems-headings">
                            <h3>Stay Upto 180 Days</h3>
                            <p>With 10 Year Visa, you can stay up to 180 days in each entry, making it ideal for
                                long-Term stay in the Kingdom of Saudi Arabia.</p>
                        </div>

                    </div>
                    <div className="tenyearvisa-cardsdiv-cardContent-carditems">

                        <div className="tenyearvisa-cardsdiv-cardContent-carditems-imgdiv">
                            <img src={imgTick} alt="imgTick"/>
                        </div>

                        <div className="tenyearvisa-cardsdiv-cardContent-carditems-headings">
                            <h3>Eligibility</h3>
                            <p>Exclusively accessible to US citizens, to explore and engage with the rich culture and
                                business ventures in Saudi Arabia.</p>
                        </div>

                    </div>
                    <div className="tenyearvisa-cardsdiv-cardContent-carditems">
                        <div className="tenyearvisa-cardsdiv-cardContent-carditems-imgdiv">
                            <img src={imgTick} alt="imgTick"/>
                        </div>

                        <div className="tenyearvisa-cardsdiv-cardContent-carditems-headings">
                            <h3>Cost-Effective</h3>
                            <p>Our 10 Year Visa is the perfect choice for those, who are looking for a cost effective
                                and flexible way to stay in Saudi Arabia. </p>
                        </div>

                    </div>
                </div>

                <div className="tenyearvisa-cardsdiv-contactformdiv">
                    <div className="tenyearvisa-cardsdiv-contactformdiv-leftside">
                        <h3>Get in touch with us</h3>
                        <p>Fast Saudi Visa is your one-stop solution for all your visa needs. We offer a multiple
                            variety of visas that is efficient and cost-effective, allowing you to explore Saudi Arabia
                            without worrying.</p>
                        <button>Apply Now</button>

                        <div className='tenyearvisa-cardsdiv-contactformdiv-leftside-socialicons'>
                            <div className="tenyearvisa-cardsdiv-contactformdiv-leftside-socialicons-heading">
                                <h4>Follow Us</h4>
                            </div>
                            <div className="tenyearvisa-cardsdiv-contactformdiv-leftside-socialicons-icons">
                                <img onClick={() => window.open('https://www.facebook.com/ChicagoHajjandUmrah','_blank')} src={fbImg} alt={fbImg}/>
                                <img onClick={() => window.open('https://wa.me/message/7AFO7UD67RBGE1','_blank')}  src={waImg} alt={waImg}/>
                                <img onClick={() => window.open('https://www.instagram.com/chicagohajj/','_blank')} src={instaImg} alt={instaImg}/>
                                <img  onClick={() => window.open('https://www.youtube.com/@ChicagoHajj21','_blank')} src={youtube} alt={youtube}/>
                                <img onClick={() => window.open('https://www.pinterest.com/chicagohajj/','_blank')} src={pinterest} alt={pinterest}/>
                                <img onClick={() => window.open('https://x.com/Chicagohajj','_blank')} src={x} alt={x}/>
                                <img onClick={() => window.open('https://www.linkedin.com/company/chicagohajj','_blank')} src={linkedin} alt={linkedin}/>

                            </div>

                        </div>
                    </div>
                    <div className="tenyearvisa-cardsdiv-contactformdiv-rightside">
                        <h2>Send us a Message</h2>



                        <div className="tenyearvisa-cardsdiv-contactformdiv-rightside-singlefield">
                            <label>Name</label>
                            <input   name="name"
                                     value={formData.name}
                                     placeholder="Name"
                                     onChange={handleChange}/>
                        </div>


                        <div className="tenyearvisa-cardsdiv-contactformdiv-rightside-singlefield">
                            <label>Phone</label>
                            <input     type="text"
                                       name="phoneNumber"
                                       value={formData.phoneNumber}
                                       placeholder="Phone Number"
                                       onChange={handleChange}/>
                        </div>

                        <div className="tenyearvisa-cardsdiv-contactformdiv-rightside-singlefield">
                            <label>Email</label>
                            <input type="email"
                                   name="email"
                                   placeholder="Email"
                                   value={formData.email}
                                   onChange={handleChange}/>
                        </div>

                        <div className="tenyearvisa-cardsdiv-contactformdiv-rightside-singlefield">
                            <label>Write a message</label>
                            <textarea   name="message"
                                        value={formData.message}
                                        placeholder="Your Message"
                                        onChange={handleChange} rows={5}/>
                        </div>

                        <div className='tenyearvisa-cardsdiv-contactformdiv-rightside-btndiv'>
                            <button onClick={handleSubmit} >Submit</button>
                        </div>

                    </div>
                </div>


            </div>


        </div>
    )
}

export default TenYearVisa;